@import "styles/variables";

$layout-width: rem-calc(400);

.component {
  position: fixed;
  z-index: 10001;
  max-width: $layout-width;
  width: 100%;
  overflow: hidden;

  @media (max-width: $mobile-width) {
    max-width: calc(100vw - #{$margin-sm * 2});
  }
}

.top-left {
  top: $margin-sm;
  left: $margin-sm;
}

.top-center {
  top: $margin-sm;
  left: calc(50% - #{$layout-width / 2});
}

.top-right {
  top: $margin-sm;
  right: $margin-sm;
}

.bottom-left {
  bottom: $margin-sm;
  left: $margin-sm;
}

.bottom-center {
  bottom: $margin-sm;
  left: calc(50% - #{$layout-width / 2});
}

.bottom-right {
  bottom: $margin-sm;
  right: $margin-sm;
}