@import "styles/variables";

.ui-container {
  max-width: rem-calc(1376);
  margin: auto;
}

.ui-columns {
  display: flex;
}

.ui-column {
  flex-grow: 1;
  flex-shrink: 0;

  &.flex {
    display: flex;
  }

  &.align-middle {
    align-items: center;
  }
}