@import "styles/variables";

button.z-button, a.z-button {
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  background-color: $primary-light;
  color: $white;
  display: inline-block;
  cursor: pointer;
  transition: 0.2s background-color ease-in;
  border: none;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.25px;

  &:hover {
    background-color: $primary;
  }

  &:active {
    background-color: $primary-50;
  };

  &:disabled {
    background-color: $primary-30;
    cursor: default;
  }

  &.skeleton {
    border: 1px solid $primary-30;
    background-color: transparent;
    color: $primary-light;
    padding: 7px 12px;

    &:hover {
      background-color: $primary-10;
    }

    &:active {
      background-color: $primary-30;
    };

    &.button-lg {
      padding: 13px 20px;
    }

    &.button-xl {
      padding: 21px 32px;
    }
  }

  &.button-lg {
    padding: 14px 20px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
  }

  &.button-xl {
    padding: 22px 32px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
  }
}

ul.z-list {
  margin: 0;
  list-style-type: none;
  padding-left: 72px;

  >li {
    margin-bottom: 40px;
    position: relative;
    font-size: 16px;

    &:before {
      display: block;
      position: absolute;
      content: '';
      width: 32px;
      height: 32px;
      background: $primary url("/promo_page/svg/vector.svg") no-repeat center;
      border-radius: 50%;
      top: 0-2px;
      left: -72px;
    }
  }

  &.simple {
    padding-left: 20px;

    >li {
      margin-bottom: 16px;
      font-size: 20px;

      &:before {
        width: 10px;
        height: 10px;
        background: $primary;
        top: 10px;
        left: -20px;
      }
    }
  }
}
