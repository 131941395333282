@import "styles/variables";

$border-color: darken($app-light-bg, 2%);

.ui.modals {
  z-index: 9998;
}

.ui.modal {
  border-radius: $def-br;
  border: rem-calc(8) solid $border-color;
  background-color: $border-color;

  > :last-child {
    border-radius: 0 0 $def-br $def-br;
  }

  > .icon.close {
    top: 0;
    right: 0;
    height: rem-calc(54);
    width: rem-calc(54);
    margin-right: 0;
    padding-top: rem-calc(3);
    color: $danger;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  > .header {
    border-radius: rem-calc(8) rem-calc(8) 0 0;
    margin-bottom: rem-calc(8);
    border-bottom: none;
    background-color: $border-color;
    line-height: 1.2 !important;
    padding: $padding-lg rem-calc(60) rem-calc(18) $padding-lg !important;
    text-transform: uppercase;
    font: {
      weight: 700;
      size: $font-md !important;
    }

    @media (min-width: 0) and (max-width: $mobile-width) {
      font-size: $font-xs2 !important;
    }
  }

  .content {
    border-radius: rem-calc(8);
    padding: $padding-lg;
    font-size: $font-sm1;

    &.scrolling {
      max-height: 80vh;
    }
  }

  .modal-actions {
    padding: $padding-lg $padding-lg 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 0) and (max-width: $mobile-width) {
  .ui.modals {
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */

    &::-webkit-scrollbar { /* WebKit */
      width: 0;
      height: 0;
    }
  }
}