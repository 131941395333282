@import "variables";
@import "node_modules/semantic-ui-sass/scss/globals/all.scss";

@import "node_modules/semantic-ui-sass/scss/collections/form.scss";
@import "node_modules/semantic-ui-sass/scss/collections/menu.scss";

@import "node_modules/semantic-ui-sass/scss/elements/button.scss";
@import "node_modules/semantic-ui-sass/scss/elements/input.scss";
@import "node_modules/semantic-ui-sass/scss/elements/label.scss";
@import "node_modules/semantic-ui-sass/scss/elements/loader.scss";
@import "node_modules/semantic-ui-sass/scss/elements/placeholder.scss";
@import "node_modules/semantic-ui-sass/scss/elements/list.scss";
@import "node_modules/semantic-ui-sass/scss/elements/icon.scss";

@import "node_modules/semantic-ui-sass/scss/modules/checkbox.scss";
@import "node_modules/semantic-ui-sass/scss/modules/dimmer.scss";
@import "node_modules/semantic-ui-sass/scss/modules/dropdown.scss";
@import "node_modules/semantic-ui-sass/scss/modules/modal.scss";
@import "node_modules/semantic-ui-sass/scss/modules/popup.scss";
@import "node_modules/semantic-ui-sass/scss/modules/search.scss";
@import "node_modules/semantic-ui-sass/scss/modules/transition.scss";
@import "node_modules/semantic-ui-sass/scss/modules/progress.scss";

$input-paddings: rem-calc(32) $padding-sm $padding-sm rem-calc(13);
$input-paddings-no-label: $padding-sm $padding-sm $padding-sm rem-calc(13);
$input-paddings-no-label-small: rem-calc(7) $padding-sm rem-calc(7) rem-calc(13);

input, textarea, select {
  min-height: rem-calc(44);
  transition-duration: 0.3s !important;
  border-color: $app-bg !important;
	font: {
		family: $openSans !important;
		size: $font-md !important;
  }
  
  &:focus {
    border-color: $brand-info !important;
  }
}

.ui.input {
  &.error {
    input, textarea, select {
      border-color: lighten($danger, 25%) !important;
    }
  }
}

.ui.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;


  &.v2 {
    border-radius: rem-calc(5);
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.1px;

    &.mini {
      font-size: rem-calc(10);
      line-height: 1.4;
    }
  }

  svg {
    margin: {
      left: -#{rem-calc(4)};
      right: $margin-xs;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &.red {
    background-color: $danger;
  }

  &.grey {
    background-color: $brand-middle-grey;
  }
}

.field > label,
.ui.form .field > label {
  color: lighten($brand-dark, 20%);
  text-transform: uppercase;
	font: {
    weight: 600;
    size: $font-xs;
  }

  &:after {
    margin: 0 !important;
    font-size: $font-sm;
    height: rem-calc(14);
    line-height: 1;
  }
}

.ui.red.label {
  font: {
    weight: 600;
    size: $font-sm;
  }
}

.ui.form .field {
  margin-bottom: $margin-lg;

  &.no-bottom-margin {
    margin-bottom: 0;
  }

  .input {
    >input {
      line-height: 1;
    }
  }

  &.disabled {
    opacity: 1;

    .input {
      >input {
        //background-color: $gray;
      }
    }
  }

  &.required {
    textarea {
      padding: $padding-xxl rem-calc(12) rem-calc(12);
    }
  }

  &.textarea-with-label {
    textarea {
      padding: $padding-xxl rem-calc(12) rem-calc(12);
    }
  }

  &.required {
    &.textarea {
      textarea {
        padding: rem-calc(12) $padding-md;
      }

      &::before {
        content: '*';
        width: 6px;
        height: 6px;
        display: inline-block;
        font-size: $font-sm;
        line-height: 1;
        position: absolute;
        top: 16px;
        left: 8px;
        color: red;
      }
    }
  }
}

div.ui.label {
	font-size: $font-sm;
}

.ui.popup {
  font-size: $font-sm;
  z-index: 99999;
}

.ui.dimmer {
  border-radius: $def-br;
  background-color: rgba($brand-darkest, 0.7);

  &.modals {
    border-radius: 0;
  }
}

.ui.dropdown > .text {
  font: {
    weight: 600;
    size: $font-sm2;
  }
}

.ui.dropdown > .menu {
  margin-top: $margin-xs;
  box-shadow: $default-box-shadow;
}

.ui.dropdown > .menu > .item {
  font: {
    size: $font-sm1;
  }

  &.selected,
  &.active {
    font-weight: 600;
  }
}

.field {
  position: relative;

  &.no-label {
    >.ui.input >input {
      padding: $input-paddings-no-label;
    }

    &.small {
      >.ui.input >input {
        min-height: 38px;
        padding: $input-paddings-no-label-small;
      }
    }
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    margin-bottom: 0 !important;
    z-index: 4;
    padding: $padding-md rem-calc(13) 0;
    line-height: 1;
  }

  > .ui.search > .ui.input,
  > .ui.input {
    z-index: 1;

    > input {
      padding: $input-paddings;
    }
  }

  &.new-design {
    >.ui.input {
      >svg {
        position: absolute;
        top: 20px;
        left: 20px;
        color: $landing-grey-50;
      }

      >input {
        padding: 20px 24px 22px;
        border: none!important;
        background-color: $landing-back-grey;
        line-height: 22px;
        color: $landing-grey!important;
        letter-spacing: 0.5px;
        font-family: $montserrat!important;
      }

      ::-webkit-input-placeholder {color:$landing-grey-50;}
      ::-moz-placeholder          {color:$landing-grey-50;}
      :-moz-placeholder           {color:$landing-grey-50;}
      :-ms-input-placeholder      {color:$landing-grey-50;}

      &.left.icon {
        >input {
          padding-left: 54px!important;
        }
      }
    }

    &.no-label {
      >.ui.input >input {
        padding: 20px 24px 22px;
      }
    }
  }

  .ui.selection.dropdown {
    min-height: rem-calc(63);
    background: transparent;
    padding: $input-paddings;
    
    .dropdown.icon {
      top: $margin-lg;
    }

    > input.search {
      padding: $input-paddings;
      line-height: 1;
    }
  }

  &.with-label {
    position: relative;
    >label {
      position: relative;
      top: rem-calc(-5)
    }
  }
}

.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
  background-color: $teal !important;
}

.ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before {
  background-color: $teal !important;
}

.ui.progress {
  margin-bottom: 0!important;
}

button.ui.button.disabled {
  opacity: 1!important;
  background-color: $disabled-btn-bg!important;
  color: $brand-middle-grey;
}

.ui.multiple.dropdown>.label {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  background-color: $app-bg;
}

.ui.radio.checkbox input:checked~.box:before, .ui.radio.checkbox input:checked~label:before {
  background: #fff;
  border-color: $teal;
  border-width: 5px;
}

.ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after {
  background-color: $white!important;
}

.ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
  color: $teal!important;
}

.ui.toggle.checkbox.semibold-label .box, .ui.toggle.checkbox.semibold-label label {
  font-weight: 600;
}

.ui.orange.button {
  background-color: $orange-light;

  &:hover {
    background-color: darken($orange-light, 3%);
  }
}