@import "styles/variables";

body {
  #nprogress {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    pointer-events: all;
  }
}