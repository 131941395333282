@import "styles/variables";

.react-calendar {
  button {
    @include border-radius(rem-calc(5));
    background-color: transparent;
    border: none;
    padding: $padding-xs;
    height: rem-calc(29);
    min-width: rem-calc(30);
    outline: none !important;
    font: {
      size: $font-sm;
    }

    &:hover {
      background-color: $app-bg;
      cursor: pointer;
    }
  }

  .react-calendar__navigation__label {
    text-transform: capitalize;
    font-size: $font-md;
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__arrow {
    line-height: 1;
    font: {
      size: $font-lg;
    }

    svg {
      font-size: $font-sm1;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    padding: $padding-xs 0;
    
    abbr {
      font: {
        weight: 600;
        size: $font-sm;
      }
      text-decoration: none;
      color: $teal;
    }
  }

  .react-calendar__tile--now {
    color: $brand;
    font: {
      weight: 700;
    };
  }

  .react-calendar__tile--active {
    background-color: $teal !important;
    color: $white !important;

    &:hover {
      background-color: $teal;
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: darken($white, 25%);
  }

  .react-calendar__century-view__decades__decade {
    font-size: $font-xs2;
  }
}