@import                  "variables";
@import                  'node_modules/swiper/swiper.scss';
@import                  'node_modules/swiper/components/navigation/navigation.scss';
@import                  'node_modules/swiper/components/pagination/pagination.scss';

.swiper-button-prev, .swiper-button-next {
  &::after {
    color: $primary;
  }
}

.swiper-pagination-bullet-active {
  background: $landing-grey;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal>.swiper-pagination-bullets {
  bottom: -40px;

  .swiper-pagination-bullet {
    margin: 0 6px;
  }
}

.swiper-container {
  overflow: visible;
}

