@import "styles/variables";

$icon-size: rem-calc(60);

.component {
  @include border-radius($def-br);
  position: relative;
  min-height: $icon-size;
  overflow: hidden;
  display: none;

  &:not(:first-child) {
    margin-top: $margin-xs;
  }
}

.animation {
  animation: appearance 1s ease-in-out;
  display: flex;
}

.icon {
  width: $icon-size;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  flex-shrink: 0;

  &.default {
    background-color: $brand-dark;
  }
  
  &.success {
    background-color: $teal;
  }

  &.info {
    background-color: $info;
  }
  
  &.warning {
    background-color: $brand;
  }
  
  &.danger {
    background-color: $danger;
  }
}

.text {
  z-index: 1;
  background-color: $brand-darkest;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text_content {
  width: calc(#{rem-calc(400)} - #{$icon-size});
  padding: $padding-md;
  color: $white;

  ul {
    padding-left: $padding-lg;
    margin: 0;
  }

  @media (max-width: $mobile-width) {
    width: calc(100vw - #{$margin-sm * 2} - #{$icon-size});
  }
}

.actions {
  text-align: right;
  padding: 0 $padding-md $padding-md;
}

@keyframes appearance {
  0% {
    opacity: 0;
    margin-left: calc(100% - #{$icon-size});
  }
  10% {
    opacity: 0;
    margin-left: calc(100% - #{$icon-size});
  }
  60% {
    opacity: 1;
    margin-left: calc(100% - #{$icon-size});
  }
  100% {
    margin-left: 0;
  }
}