@import "styles/variables";

html {
	font-size: 16px;
}

html,
body {
	@media (max-width: $tablet-width) {
		width: 100vw;
	}
}

body {
	background-color: #fff;
	font: {
		family: $openSans;
	};

	&.with-search-results {
		overflow: hidden;
	}

	&.disable-scroll {
		height: 100vh;
		overflow-y: hidden;
	}

	@media (max-width: $tablet-width) {
		&.menu-opened {
			overflow: hidden;
			
			[class^="layout_menu"],
			[class^="site-menu_root"] {
				left: 0;
			}
	
			[class^="layout_menu_overlay"],
			[class^="site-menu_overlay"] {
				right: 0;
				background-color: rgba($brand-dark, 0.5);
			}
		}

		&.search-visible {
			[class^="layout_search"] {
				display: block;
			}

			[class^="layout_page"] {
				margin-top: $finder-height;
			}
		}
	}
}

#__next {
	height: 100vh;
}

.hidden-input,
.no-display {
	display: none;
}

.no-paddings {
	padding: 0 !important;
}

.no-scroll {
	overflow: hidden;
}

#jcont.__auto_3S {
	.main_Lb {
		display: none !important;
	}
}

#jvlabelWrap,
.__jivoMobileButton {
	display: none !important;
}

nextjs-portal {
	position: relative;
	z-index: 9999;
}

.param-block {
	margin-bottom: $margin-md;

	.param-name {
		color: $label-color;
		text-transform: uppercase;
		font-size: $font-xs;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.semi-bold {
	font-weight: 600;
}

.bold {
	font-weight: 700;
}

.extra-bold {
	font-weight: 800;
}

pre {
	margin: 0;
	font-family: "Montserrat",  sans-serif;
}

.link-btn {
	font-size: 12px!important;
	text-transform: uppercase;
	font-weight: 600;
	padding: 8px 0;
	cursor: pointer;
	color: $link-color!important;

	&:hover {
		color: $link-hover-color!important;
	}
}


@media (min-width: 0) and (max-width: rem-calc(650)) {
	.hide-mobile {
		display: none;
	}
}

//fix Verbox z-index
.online-chat-root {
  .online-chat-components-wrapper {
		.top-z-index {
			z-index: 9990 !important;
		}
	}
}

.full-width {
	width: 100%!important;
}

.color_gray {
	color: $brand-dark!important;
}

.color_teal {
	color: $teal!important;
}

.color_danger {
	color: $danger!important;
}

.color_orange {
	color: $orange!important;
}

.color_yellow {
	color: $brand-yellow!important;
}

//landing
.page-title {
	font-family: 'Montserrat', sans-serif;

	font-weight: 800;
	font-size: 72px;
	line-height: 1.2;
	letter-spacing: -1.5px;
	color: $landing-dark-grey;
	margin-bottom: 40px;

	@media (max-width: $tablet-width) {
		font-size: 56px;
	}

	@media (max-width: $mobile-width) {
		font-size: 40px;
	}
}

.page-description {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 1.4;
	letter-spacing: 0.15px;
	max-width: 700px;

	@media (max-width: $tablet-width) {
		font-size: 18px;
	}

	@media (max-width: $mobile-width) {
		font-size: 16px;
		font-weight: 600;
	}
}

.section-title {
	font-family: 'Montserrat', sans-serif;
	font-size: 56px;
	line-height: 1.2;
	font-weight: 800;
	letter-spacing: -0.5px;

	@media (max-width: $tablet-width) {
		font-size: 48px;
	}

	@media (max-width: $mobile-width) {
		font-size: 40px;
	}
}

/* ----------------------------------------- */

.shepherd-cancel-icon:hover{
	color:rgba(0,0,0,.75)
  }
  
  .shepherd-has-title .shepherd-content .shepherd-cancel-icon{
	color:hsla(0,0%,50%,.75)
  }
  
  .shepherd-content{
	padding: 20px;
	font-family: $openSans;
  }

  .shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover{
	color:rgba(0,0,0,.75)
  }
  
  .shepherd-title{
	font-family: $openSans;
	color:rgba(0,0,0,.75);
	display:flex;
	flex:1 0 auto;
	flex-direction: column;
	gap:-15px;
	font-size:16px;
	font-weight:700;
	overflow-wrap: break-word;
	word-wrap: break-all;
	hyphens: auto;
	margin:0;
	padding:0;
	p{
margin-bottom:0;
	}
  }
  
  .shepherd-header{
	font-family: $openSans;
	background:#16293F;
	align-items:center;
	word-wrap: break-all;
	overflow-wrap: break-word;
	hyphens: manual;
	border-top-left-radius:5px;
	border-top-right-radius:5px;
	display:flex;
	justify-content:flex-end;
	line-height:2em;
	// padding-bottom: 5px;
	// padding:.75rem .75rem 0
  }
  
  .shepherd-has-title .shepherd-content .shepherd-header{
	background:#e6e6e6;
	font-family: $openSans;
  }
  
  .shepherd-text{
	font-family: $openSans;
	/* color:rgba(0,0,0,.75); */
  color:white!important;
	font-size:15px;
  line-height:1.3em;
  padding-bottom: 15px;
  padding-top:5px;
//   padding:.75em;
  }
  
  .shepherd-text p{
	margin-top:0;
	font-family: $openSans;
  }
  
  .shepherd-text p:last-child{
	margin-bottom:0
  }
  
  .shepherd-content{
	border-radius:5px;
	outline:none;
  }
  
  .shepherd-element{
	background:#fff;
	border-radius:5px;
	box-shadow:0 1px 4px rgba(0,0,0,.2);
	max-width:400px;
	opacity:0;
	outline:none;
	transition:opacity .3s,visibility .3s;
	visibility:hidden;
	width:100%;
	z-index:9999}
  
	.shepherd-enabled.shepherd-element{
	  opacity:1;
	  visibility:visible
	}
  
	.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered){
	  opacity:0;
	  pointer-events:none;
	  visibility:hidden
	}
  
	.shepherd-element,.shepherd-element *,.shepherd-element :after,.shepherd-element :before{
	  box-sizing:border-box
	}
  
	.shepherd-arrow,.shepherd-arrow:before{
	  height:16px;
	  position:absolute;
	  width:16px;
	  z-index:-1
	}
  
	.shepherd-arrow:before{
	  background:#16293F;
	  content:"";
	  transform:rotate(45deg)}.shepherd-element[data-popper-placement^=top]>
	  .shepherd-arrow{bottom:-8px}.shepherd-element[data-popper-placement^=bottom]>
	  .shepherd-arrow{top:-8px}.shepherd-element[data-popper-placement^=left]>
	  .shepherd-arrow{right:-8px}.shepherd-element[data-popper-placement^=right]>
	  .shepherd-arrow{left:-8px}.shepherd-element.shepherd-centered>.shepherd-arrow{
		opacity:0
	  }
	  .shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>
	  .shepherd-arrow:before{background-color:#16293F}
	  .shepherd-target-click-disabled.shepherd-enabled.shepherd-target,.shepherd-target-click-disabled.shepherd-enabled.shepherd-target *{pointer-events:none}
  .shepherd-modal-overlay-container{
	height:0;
	left:0;
	opacity:0;
	overflow:hidden;
	pointer-events:none;
	position:fixed;
	top:0;
	transition:all .3s ease-out,height 0ms .3s,opacity .3s 0ms;
	width:100vw;
	z-index:9997}
	.shepherd-modal-overlay-container.shepherd-modal-is-visible{
	  height:100vh;
	  z-index: 9999;
	//   pointer-events:none;
	  opacity:.5;
	  transform:translateZ(0);
	  transition:all .3s ease-out,height 0s 0s,opacity .3s 0s
	}
	.shepherd-modal-overlay-container.shepherd-modal-is-visible path{
	  pointer-events:all!important;
	}
  
  .shepherd-footer{
	display: flex;
	justify-content: space-between!important;
  }
  
  .shepherd-header{
  background-color: #16293F!important;
  
  }
  
  .shepherd-header .shepherd-title{
	color: white !important;
	font-weight: 800!important;
  }
  .highlight{
  border-radius: 15px;
  display: block;
  height: 100px;
  position: absolute;
  width: 200px;
  }


